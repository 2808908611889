import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { ChangeEvent, FC } from "react";
import IconSearch from "../../../icons/navigation/icon-search.svg";
import IconClear from "../../../icons/navigation/icon-close-small.svg";
import muiStyles from "./muiStylesDesktopSearch";

const useStyles = makeStyles(muiStyles);

interface Props {
  clearSearchInput: () => void;
  handleClickOpenSearch: () => void;
  handleSearchInputChanges: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  searchValue: string;
}

const DesktopSearch: FC<Props> = ({
  clearSearchInput,
  handleClickOpenSearch,
  handleSearchInputChanges,
  searchValue,
}) => {
  const classes = useStyles(useStyles);

  return (
    <FormControl className={classes.search}>
      <TextField
        className={classes.input}
        id="input-with-icon-textfield"
        margin="normal"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleSearchInputChanges(event);
          handleClickOpenSearch();
        }}
        placeholder="Search network by users"
        value={searchValue}
        variant="filled"
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <img src={IconSearch} alt="icon search" />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={clearSearchInput}>
                <img src={IconClear} alt="icon clear" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default DesktopSearch;
