import React, { useState } from "react";
import * as styles from "./accountSettings.module.scss";
import UserAvatar from "../Common/UserAvatar";
import { useSelector } from "react-redux";
import { selectAuthUser } from "../../redux/selectors/authSelectors";
import { User } from "../../api/usersAPI";
import PasswordChange from "./PasswordChange";
import NextButton from "../../icons/button-next.svg";
import ActivityPreferences from "./ActivityPreferences";
import { useIsSmall } from "../../utils";
import Loader from "../AppLoader";
import { selectPasswordChangeLoading } from "../../redux/selectors/passwordSelectors";
import { selectActivityPreferencesLoading } from "../../redux/selectors/activityTypesSelectors";
import { Divider } from "@material-ui/core";
import clsx from "clsx";

const AccountSettings = () => {
  const isSmall = useIsSmall();
  const curUser: User | undefined = useSelector(selectAuthUser);

  const setActivityTypesLoading = useSelector(selectActivityPreferencesLoading);
  const changePasswordLoading = useSelector(selectPasswordChangeLoading);
  const [isPasswordChangeActive, setIsPasswordChangeActive] = useState<boolean>(
    false
  );
  const togglePasswordChange = () => {
    setIsPasswordChangeActive(!isPasswordChangeActive);
  };

  const [
    isActivityPreferencesActive,
    setIsActivityPreferencesActive,
  ] = useState<boolean>(false);

  const showPasswordChangeOrPreferences =
    isPasswordChangeActive || isActivityPreferencesActive;
  // For https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/click-events-have-key-events.md
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const emptyHandler = () => {};

  const passwordChangeOrPreferences = () => {
    if (!curUser) {
      return null;
    }

    if (isPasswordChangeActive) {
      return (
        <PasswordChange
          togglePasswordChange={togglePasswordChange}
          userId={curUser.id}
        />
      );
    } else if (isActivityPreferencesActive) {
      return (
        <ActivityPreferences
          toggleActivityPreferences={toggleActivityPreferences}
          userActivityTypes={curUser.activityTypes}
          userId={curUser.id}
        />
      );
    }
  };

  const toggleActivityPreferences = () => {
    setIsActivityPreferencesActive(!isActivityPreferencesActive);
  };

  return (
    <>
      <Loader loading={changePasswordLoading || setActivityTypesLoading} />
      {curUser && (
        <div className={styles.accountSettingsPageContainer}>
          <div className={isSmall ? styles.header : styles.headerDesktop}>
            <h3>Account Settings</h3>
          </div>
          {isSmall && showPasswordChangeOrPreferences ? (
            passwordChangeOrPreferences()
          ) : (
            <div className={styles.accountSettingsContainer}>
              <div className={styles.profileContainer}>
                <div className={isSmall ? styles.section : ""}>
                  <span>PROFILE</span>
                  <div className={styles.avatarContainer}>
                    <UserAvatar
                      firstName={curUser.firstName}
                      lastName={curUser.lastName}
                      large
                    />
                  </div>
                </div>
              </div>
              {!isSmall && showPasswordChangeOrPreferences ? (
                passwordChangeOrPreferences()
              ) : (
                <div className={styles.infoContainer}>
                  <div className={styles.contact}>
                    For changes to your information, please contact
                    <span className={styles.email}> admin@motko.co </span>
                  </div>
                  <Divider />
                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>Full Name</div>
                    <div
                      className={styles.sectionText}
                    >{`${curUser?.firstName} ${curUser.lastName}`}</div>
                  </div>
                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>Email</div>
                    <div className={styles.sectionText}>{curUser.email}</div>
                  </div>
                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>Phone</div>
                    <div className={styles.sectionText}>
                      {curUser?.phoneNumber}
                    </div>
                  </div>
                  <div className={styles.departmentContainer}>
                    <div className={styles.sectionTeam}>
                      <div className={styles.sectionHeader}>Department</div>
                      <div className={styles.sectionText}>
                        {curUser?.department}
                      </div>
                    </div>
                    <div className={styles.sectionTeam}>
                      <div className={styles.sectionHeader}>Team</div>
                      <div className={styles.sectionText}>{curUser.team}</div>
                    </div>
                  </div>
                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>Role</div>
                    <div className={styles.sectionText}>{curUser.role}</div>
                  </div>
                  <div
                    className={styles.sectionNav}
                    onClick={togglePasswordChange}
                    role="button"
                    tabIndex={0}
                    onKeyUp={emptyHandler}
                  >
                    <div className={styles.sectionHeader}>
                      Change Password
                      <img
                        className={styles.navButton}
                        src={NextButton}
                        alt="pagination_button_forward"
                      />
                    </div>
                  </div>
                  <div
                    className={styles.sectionNav}
                    onClick={toggleActivityPreferences}
                    role="button"
                    tabIndex={0}
                    onKeyUp={emptyHandler}
                  >
                    <div
                      className={clsx(
                        styles.sectionHeader,
                        isSmall ? styles.lastHeader : ""
                      )}
                    >
                      Change Activity Preferences
                      <img
                        className={styles.navButton}
                        src={NextButton}
                        alt="pagination_button_forward"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AccountSettings;
