import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "./allEmployees.module.scss";
import MobileFiltering from "./MobileFiltering";
import EmployeeList from "./EmployeeList";
import FilterButton from "../../icons/button-filter-alternate.svg";
import { fetchActivityTypes } from "../../redux/actions/activityTypesActions";
import {
  ActivityTypeOption,
  selectActivityTypesForDropdown,
} from "../../redux/selectors/activityTypesSelectors";
import {
  DepartamentOption,
  selectDepartmentsForDropdown,
} from "../../redux/selectors/departmentsSelectors";
import { fetchDepartments } from "../../redux/actions/departmentsActions";
import NetworkFilterDropdown from "./NetworkFilterDropdown";
import { useIsSmall } from "../../utils";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import muiStylesAllEmployees from "./muiStylesAllEmployees";
import { AddEmployeeDialog } from "./AddEmployeeDialog";

const useStyles = makeStyles(muiStylesAllEmployees);

const AllEmployees: FC = () => {
  const classes = useStyles();
  const isSmall = useIsSmall();

  const dispatch = useDispatch();

  const [addEmployeeDialog, setAddEmployeeDialog] = useState(false);
  const openAddEmployeeDialog = () => {
    setAddEmployeeDialog(true);
  };
  const onDisableConfirmationClose = () => setAddEmployeeDialog(false);

  const activityFilters: ActivityTypeOption[] = useSelector(
    selectActivityTypesForDropdown
  );
  const departmentFilters: DepartamentOption[] = useSelector(
    selectDepartmentsForDropdown
  );

  const [isFilteringActive, setIsFilteringActive] = useState<boolean>(false);
  const toggleFiltering = () => {
    setIsFilteringActive(!isFilteringActive);
  };

  const [activityFilter, setActivityFilter] = React.useState<string | number>(
    activityFilters[0].id
  );
  const handleActivityFilter = (event: ChangeEvent<{ value: unknown }>) => {
    setActivityFilter(event.target.value as string);
  };

  const [departmentFilter, setDepartmentFilter] = React.useState<
    string | number
  >(departmentFilters[0].id);
  const handleDepartmentFilter = (event: ChangeEvent<{ value: unknown }>) => {
    setDepartmentFilter(event.target.value as string);
  };

  const getDropDownFilters = () => {
    dispatch(fetchActivityTypes());
    dispatch(fetchDepartments());
  };

  useEffect(() => {
    getDropDownFilters();
  }, [dispatch]);

  return (
    <>
      <AddEmployeeDialog
        isOpen={addEmployeeDialog}
        onClose={onDisableConfirmationClose}
      />
      <div className={styles.networkContainer}>
        <div className={isSmall ? styles.header : styles.headerDesktop}>
          {isSmall && (
            <img
              role="button"
              className={styles.filterButton}
              src={FilterButton}
              alt="filter-button"
              onClick={toggleFiltering}
            />
          )}
          <h3>All Employees</h3>
          {!isSmall && (
            <>
              <div className={styles.addEmployeeContainer}>
                <Button
                  color="secondary"
                  variant="contained"
                  className={clsx(
                    classes.btnAddDepartment,
                    classes.secondaryButton
                  )}
                  onClick={openAddEmployeeDialog}
                >
                  Add Employees
                </Button>
              </div>
              <div className={styles.dropdownContainer}>
                <NetworkFilterDropdown
                  mobile={false}
                  dropdownOptions={departmentFilters}
                  filter={departmentFilter}
                  handleFilter={handleDepartmentFilter}
                />
                <NetworkFilterDropdown
                  mobile={false}
                  dropdownOptions={activityFilters}
                  filter={activityFilter}
                  handleFilter={handleActivityFilter}
                  lastChild
                />
              </div>
            </>
          )}
        </div>
        {isFilteringActive && isSmall ? (
          <MobileFiltering
            toggleFiltering={toggleFiltering}
            activityFilter={activityFilter}
            handleActivityFilter={handleActivityFilter}
            activityFilters={activityFilters}
            departmentFilter={departmentFilter}
            handleDepartmentFilter={handleDepartmentFilter}
            departmentFilters={departmentFilters}
          />
        ) : (
          <EmployeeList
            activityFilter={activityFilter}
            departmentFilter={departmentFilter}
          />
        )}
      </div>
    </>
  );
};

export default AllEmployees;
