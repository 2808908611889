import { ThemeProvider } from "@material-ui/core/styles";
import { Router } from "@reach/router";
import React from "react";
import PrivateRoute from "../components/PrivateRoute";
import AccountSettingsPage from "../private-pages/AccountSettingsPage";
import CalendarPage from "../private-pages/CalendarPage";
import { CompanySettingsPage } from "../private-pages/CompanySettingsPage";
import NetworkStructurePage from "../private-pages/NetworkStructurePage";
import { OrganizationStructurePage } from "../private-pages/OrganizationStructurePage";
import { theme } from "../styles/mui/theme";
import ProfilePage from "../private-pages/Profile";
import ProfileCalendarPage from "../private-pages/ProfileCalendar";
import Notifier from "../components/Snackbar/Notifier";
import AppSnackbarProvider from "../components/Snackbar/SnackbarProvider";
import { useIsSmall } from "../utils";
import { MessageDialog } from "../components/MessageDialog";
import { useSelector } from "react-redux";
import { selectShowWelcomeMessage } from "../redux/selectors/onboardingSelectors";
import { useDispatch } from "react-redux";
import { hideWelcomeMessage } from "../redux/actions/onboardingActions";
import AllEmployeesPage from "../private-pages/AllEmployeesPage";
import PulsePage from "../private-pages/PulsePage";

const MaxCoexistingSnackbarsMobile = 3;
const MaxCoexistingSnackbarsDesktop = 5;

const App = () => {
  // note: layout should not be included in the App component, since direct access to a protected resource
  // flashes the Layout before the redirection.

  const isSmall = useIsSmall();
  const dispatch = useDispatch();
  const isWelcomeMessageOpened = useSelector(selectShowWelcomeMessage);

  return (
    <ThemeProvider theme={theme}>
      <MessageDialog
        isOpen={isWelcomeMessageOpened}
        onClose={() => {
          dispatch(hideWelcomeMessage());
        }}
        header={"Well done!"}
        description={
          "To get started, browse the network, book an activity and get moving!" +
          " And remember to join your colleagues – let's elevate the world together!"
        }
      />
      <AppSnackbarProvider
        maxSnackbars={
          isSmall ? MaxCoexistingSnackbarsMobile : MaxCoexistingSnackbarsDesktop
        }
      >
        <Notifier />
        <Router basepath="/app">
          <PrivateRoute path="/profile/:userId" component={ProfilePage} />
          <PrivateRoute
            path="/profile/:userId/calendar"
            component={ProfileCalendarPage}
          />
          <PrivateRoute path="/calendar" component={CalendarPage} />
          <PrivateRoute
            path="/account-settings"
            component={AccountSettingsPage}
          />
          <PrivateRoute
            path="/company-settings"
            component={CompanySettingsPage}
            companyAdminRestricted
          />
          <PrivateRoute
            path="/all-employees"
            component={AllEmployeesPage}
            companyAdminRestricted
          />
          <PrivateRoute
            path="/network-structure"
            component={NetworkStructurePage}
            employeeRestricted
          />
          <PrivateRoute
            path="/organization-structure"
            component={OrganizationStructurePage}
            companyAdminRestricted
          />
          <PrivateRoute path="/pulse" component={PulsePage} />
        </Router>
      </AppSnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
