import * as yup from "yup";
import moment from "moment";

const ActivitySchema = yup.object().shape({
  title: yup.string().required("This field is required"),
  startDate: yup
    .string()
    .required("This field is required")
    .typeError("Invalid date"),
  startTime: yup
    .string()
    .required("This field is required")
    .typeError("Invalid date"),
  endTime: yup
    .string()
    .required("This field is required")
    .test(
      "is-greater",
      "End time should be greater than start time",
      function (value) {
        const { startTime } = this.parent;

        return moment(value, "hh:mm").isAfter(moment(startTime, "hh:mm"));
      }
    )
    .typeError("Invalid date"),
  attendees: yup.string(),
  location: yup.string().nullable(),
  description: yup.string().nullable(),
});

export default ActivitySchema;
