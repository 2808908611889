import { RootState } from "../store";

export interface DepartamentOption {
  id: number | string;
  name: string;
}

export const selectDepartments = (state: RootState) =>
  state.departments.departments;

export const selectDepartmentsLoading = (state: RootState) =>
  state.departments.loading;

export const selectDepartmentsError = (state: RootState) => ({
  error: state.departments.error,
  message: state.departments.errorMessage,
  details: state.departments.errorDetails,
});

export const selectDepartmentsForDropdown = (state: RootState) => [
  { name: "All Departments", id: "all" },
  ...(state.departments.departments || []),
];
