import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "./network.module.scss";
import MobileFiltering from "./MobileFiltering";
import UserList from "./UserList";
import FilterButton from "../../icons/button-filter-alternate.svg";
import { fetchActivityTypes } from "../../redux/actions/activityTypesActions";
import {
  ActivityTypeOption,
  selectActivityTypesForDropdown,
} from "../../redux/selectors/activityTypesSelectors";
import {
  DepartamentOption,
  selectDepartmentsForDropdown,
} from "../../redux/selectors/departmentsSelectors";
import { fetchDepartments } from "../../redux/actions/departmentsActions";
import NetworkFilterDropdown from "./NetworkFilterDropdown";
import { useIsSmall } from "../../utils";

const NetworkPage: FC = () => {
  const isSmall = useIsSmall();

  const dispatch = useDispatch();
  const activityFilters: ActivityTypeOption[] = useSelector(
    selectActivityTypesForDropdown
  );
  const departmentFilters: DepartamentOption[] = useSelector(
    selectDepartmentsForDropdown
  );

  const [isFilteringActive, setIsFilteringActive] = useState<boolean>(false);
  const toggleFiltering = () => {
    setIsFilteringActive(!isFilteringActive);
  };

  const [activityFilter, setActivityFilter] = React.useState<string | number>(
    activityFilters[0].id
  );
  const handleActivityFilter = (event: ChangeEvent<{ value: unknown }>) => {
    setActivityFilter(event.target.value as string);
  };

  const [departmentFilter, setDepartmentFilter] = React.useState<
    string | number
  >(departmentFilters[0].id);
  const handleDepartmentFilter = (event: ChangeEvent<{ value: unknown }>) => {
    setDepartmentFilter(event.target.value as string);
  };

  const getDropDownFilters = () => {
    dispatch(fetchActivityTypes());
    dispatch(fetchDepartments());
  };

  useEffect(() => {
    getDropDownFilters();
  }, [dispatch]);

  return (
    <div className={styles.networkContainer}>
      <div className={isSmall ? styles.header : styles.headerDesktop}>
        {isSmall && (
          <img
            role="button"
            className={styles.filterButton}
            src={FilterButton}
            alt="filter-button"
            onClick={toggleFiltering}
          />
        )}
        <h3>Network</h3>
        {!isSmall && (
          <div className={styles.dropdownContainer}>
            <NetworkFilterDropdown
              mobile={false}
              dropdownOptions={departmentFilters}
              filter={departmentFilter}
              handleFilter={handleDepartmentFilter}
            />
            <NetworkFilterDropdown
              mobile={false}
              dropdownOptions={activityFilters}
              filter={activityFilter}
              handleFilter={handleActivityFilter}
              lastChild
            />
          </div>
        )}
      </div>
      {isFilteringActive && isSmall ? (
        <MobileFiltering
          toggleFiltering={toggleFiltering}
          activityFilter={activityFilter}
          handleActivityFilter={handleActivityFilter}
          activityFilters={activityFilters}
          departmentFilter={departmentFilter}
          handleDepartmentFilter={handleDepartmentFilter}
          departmentFilters={departmentFilters}
        />
      ) : (
        <UserList
          activityFilter={activityFilter}
          departmentFilter={departmentFilter}
        />
      )}
    </div>
  );
};

export default NetworkPage;
