import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateDepartament,
  createDepartamentKeys,
} from "../../api/departmentsAPI";
import {
  addDepartment,
  clearDepartmentsErrors,
} from "../../redux/actions/departmentsActions";
import {
  selectDepartmentsError,
  selectDepartmentsLoading,
} from "../../redux/selectors/departmentsSelectors";
import {
  muiStylesButtons,
  muiStylesDialog,
  muiStylesFormControlLabel,
} from "./muiStylesOrganizationStructure";
import { addDepartmentSchema } from "./validationSchemas";

const useButtonStyles = makeStyles(muiStylesButtons);
const useDialogStyles = makeStyles(muiStylesDialog);
const useInputStyles = makeStyles(muiStylesFormControlLabel);

interface AddDepartmentDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddDepartmentDialog: FC<AddDepartmentDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const buttonClasses = useButtonStyles();
  const classes = useDialogStyles();
  const inputClasses = useInputStyles();
  const dispatch = useDispatch();
  const backendLoading = useSelector(selectDepartmentsLoading);
  const backendError = useSelector(selectDepartmentsError);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isValid, isSubmitting },
    setError,
    clearErrors,
    reset,
  } = useForm<CreateDepartament>({
    mode: "onChange",
    resolver: yupResolver(addDepartmentSchema),
  });
  const addDepartmentSubmit = handleSubmit((data) => {
    dispatch(addDepartment(data));
  });
  const close = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  useEffect(() => {
    createDepartamentKeys.forEach((k) => clearErrors(k));
    dispatch(clearDepartmentsErrors());
  }, [clearErrors, dispatch, isOpen]);

  useEffect(() => {
    if (!backendLoading && isSubmitSuccessful && !backendError.error) {
      close();
    } else if (backendError.error) {
      if (backendError.message === "Validation failed") {
        Object.entries(backendError.details || {})
          .filter(([k]) =>
            createDepartamentKeys.includes(k as keyof CreateDepartament)
          )
          .forEach(([key, keyErrors]) =>
            setError(key as keyof CreateDepartament, {
              message: keyErrors.join(" "),
            })
          );
      } else {
        // TODO: handle other than validation errors
        // eslint-disable-next-line no-console
        console.log(backendError);
      }
    }
  }, [
    backendError,
    backendLoading,
    close,
    isSubmitSuccessful,
    setError,
    isOpen,
  ]);

  return (
    <Dialog
      className={classes.backgroundDialog}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={close}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5>Add department</h5>
        <IconButton size="small" onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form id="add-department-form" onSubmit={addDepartmentSubmit}>
          <FormControlLabel
            control={
              <TextField
                {...register("name")}
                type="text"
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="filled"
                fullWidth
              />
            }
            classes={inputClasses}
            labelPlacement="top"
            label="Department name"
          />
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={close}
          className={buttonClasses.secondaryButton}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="add-department-form"
          className={buttonClasses.primaryButton}
          disabled={!isValid || isSubmitting}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
